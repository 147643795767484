<template>
  <div>
    <template>
        <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
                <b-breadcrumb :items="breadCrumb()" />
            </div>
        </div>
    </template>

    <b-card>

        <b-row v-if="checkPermission('custom-forms', 'Update Item')">
            <b-col md=12>
                <div class="  demo-inline-spacing float-right ">                
                    <b-button variant="primary" class="mt-0 mb-1" @click="$router.push({name:'flash-item', params: { site_id: site ? site : null } })"
                    v-if="disabledOptions == false"
                    >
                        <feather-icon icon="GridIcon" class="mr-25"/>
                        <span>Items</span>
                    </b-button>

                    <b-button
                      variant="danger"
                      class="mt-0 mb-1"
                      @click="openLocations()"
                      v-if="disabledOptions == false"
                    >
                    <feather-icon
                        icon="TargetIcon"
                        class="mr-25"
                      />
                      <span>Locations</span>
                  </b-button>
                </div>
            </b-col>
        </b-row>

        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
            <div class="alert-body">
                {{error_message}}
            </div>
        </b-alert>

        <b-row>

          <b-col md="12">              
              <h4 class="card-title float-left">Flash Times</h4>              
          </b-col>
          
        </b-row>

        <b-row>
        <b-col md="4">
            <b-form-group
                label="Project Site"
                class="required"

              >
                <b-form-select v-model="site"  @change="siteChange()" :disabled="disabledOptions">

                  <b-form-select-option value="" disabled>Select</b-form-select-option>
                  
                  <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                  
                  
                </b-form-select>
                  
              </b-form-group>
            
        </b-col>

        <b-col md="4">
            <b-form-group label="Shift" class="required" >
                <b-form-select v-model="shift" @change="getLocationData()" :disabled="disabledOptions">
                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="shift._id" v-for="shift in shifts" :key="shift._id">{{shift.shift | capitalize}}</b-form-select-option>
                </b-form-select>
            </b-form-group>                                
        </b-col>

        <b-col md="4" >

          <b-form-group
            label="Date"
            class="required"

          > 
            <b-form-datepicker
            
              v-model="date"
              :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
              locale="en"
              :disabled="disabledOptions"
              
            />

              
          </b-form-group>
        </b-col>

        
      </b-row>

      <b-row>
        <b-col md="4">
            <b-form-group label="Month" class="required">
                <b-form-select v-model="month" @change="getLocationData()" :disabled="disabledOptions">
                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="audit" v-for="(audit,i) in months" :key="i">{{audit}}</b-form-select-option>
                </b-form-select>
            </b-form-group>                                
        </b-col>

        <b-col md="4">
            <b-form-group label="Year" class="required">
                <b-form-select v-model="year" @change="getLocationData()" :disabled="disabledOptions">
                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="audit" v-for="(audit,i) in years" :key="i">{{audit}}</b-form-select-option>
                </b-form-select>
            </b-form-group>                                
        </b-col>

        <b-col md="4">
          <b-form-group label="Inspected By" class="required">
            <b-form-input                
              v-model="inspected_by"
              type="text"
              autocomplete="off"
              :disabled="disabledOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

    </b-card>

    <b-form @submit="formSubmit">
    
    <b-card no-body class="mb-1" v-for="(list,i) in checklistData" :key="i">
      <div v-if="site && shift != ''">
        <div>
          <b-row class="mt-1 mb-1 ml-1 mr-1">

            <b-col md ="8">
              <b-row>

                <b-col md ="3">
              
                  <b-form-select v-model="list.building" @change="getArea(list.building,i)" :disabled="(checkdisabled() && disabledOptions)" class="mb-1">

                    <b-form-select-option value="" disabled>Select Building</b-form-select-option>
                    
                    <b-form-select-option :value="building._id" v-for="building in buildings" :key="building._id" :disabled="checkBuildingDisabled(building._id,i)">{{building.name | capitalize}}</b-form-select-option>
                    
                  </b-form-select>
                </b-col>

                <b-col md ="3">
                  
                  <b-form-select v-model="list.area" @change="updateInfo(list.area,i)" :disabled="(checkdisabled() && disabledOptions)" class="mb-1">

                    <b-form-select-option value="" disabled>Select Area Code</b-form-select-option>
                    
                    <b-form-select-option :value="code.code" v-for="code in list.allcodes" :key="i + '' + code.code" :disabled=checkDisableArea(code.code,i) >{{code.code}}</b-form-select-option>
                    
                  </b-form-select>

                </b-col>

                <b-col md ="6">

                  <b-form-input                
                    v-model="list.info"
                    type="text"
                    autocomplete="off"
                    readonly
                    class="mb-1"
                  />

                </b-col>
              </b-row>
            </b-col>
            <b-col md ="4">
              <b-row>

                <b-col md ="5">

                  <b-button
                      
                      variant="success"
                      class="mr-1 float-left"
                      @click="getAreaItems(i)"
                      v-if="list.area != ''"
                      :disabled="(checkdisabled() && disabledOptions)"
                      >
                     Add Area
                    </b-button>
                  
                </b-col>

                <b-col md ="7">
                  
                    <b-button
                      
                      variant="secondary"
                      class="float-right"
                      @click="removeBuilding(i)"
                      :disabled="(checkdisabled() && disabledOptions)"
                      >
                     Remove Building
                    </b-button>
                </b-col>

              </b-row>
            </b-col>

            
            
          </b-row>
        </div>
        <div v-if="list.data.length > 0">
          <b-card no-body>
            <b-tabs pills class="mt-2 tab-desktop-view-left margin_top_zero_mobile table_button_tab">

              <b-tab :title="it.code" :active="((list.data.length -1) == j)" v-for="(it,j) in list.data" :key="i + '' + it.order + Math.random()" class="mt-0" lazy>
                

                <b-table
                    responsive
                    show-empty
                    ref = "refUserListTable"
                    :class = "getClass(it.num_area)"
                    primary-key = "id"
                    empty-text = "No records found"
                    :fields = "getTableHeader(it.num_area)"
                    :items = "it.item_data"
                    thead-tr-class="d-none"
                    v-if="site != ''"
                >

                  <template slot="thead-top" slot-scope="data">
                    <tr>
                      
                      <th :colspan="2" style="background-color: #343d55; color:black;">
                        
                        <pre class="preFT mb-0"><h7 class="mb-0" style="color:#d0d2d6;font-size: 0.857rem;">{{getAreaName(it.code,it.allcodes)}}</h7></pre>
                        
                      </th>
                      <th :colspan="it.num_area">

                        <b-button
                          size="sm"
                          variant="danger"
                          class="float-right"
                          @click="removeAreaData(i,j)"
                          v-if="((it.db_location == false))"
                          :disabled="(checkdisabled() && disabledOptions)"
                          >
                         Remove
                        </b-button>

                        <b-button
                          size="sm"
                          variant="danger"
                          class="float-right"
                          @click="removeAreaDBLocation(i,j)"
                          v-if="((it.db_location == true))"
                          :disabled="(checkdisabled() && disabledOptions)"
                          >
                         Remove
                        </b-button>

                      </th>
                    </tr>
                    <tr>
                
                      <th colspan="1"> 
                        S.No
                      </th>

                      <th colspan="1"> 
                        Item Name
                      </th>
                      <!-- Max 20 Th will have -->
                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 1"> 
                        {{it.area_1}}
                      </th>

                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 2"> 
                        {{it.area_2}}
                      </th>

                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 3"> 
                        {{it.area_3}}
                      </th>

                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 4"> 
                        {{it.area_4}}
                      </th>
                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 5"> 
                        {{it.area_5}}
                      </th>
                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 6"> 
                        {{it.area_6}}
                      </th>
                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 7"> 
                        {{it.area_7}}
                      </th>
                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 8"> 
                        {{it.area_8}}
                      </th>
                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 9"> 
                        {{it.area_9}}
                      </th>
                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 10"> 
                        {{it.area_10}}
                      </th>
                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 11"> 
                        {{it.area_11}}
                      </th>
                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 12"> 
                        {{it.area_12}}
                      </th>
                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 13"> 
                        {{it.area_13}}
                      </th>
                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 14"> 
                        {{it.area_14}}
                      </th>
                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 15"> 
                        {{it.area_15}}
                      </th>
                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 16"> 
                        {{it.area_16}}
                      </th>
                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 17"> 
                        {{it.area_17}}
                      </th>
                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 18"> 
                        {{it.area_18}}
                      </th>
                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 19"> 
                        {{it.area_19}}
                      </th>
                      <th colspan="1" style="text-align:center" v-if="it.num_area >= 20"> 
                        {{it.area_20}}
                      </th>
                    </tr>
                  </template>
                  <template #cell(sno)="items">
           
                    <p class="wordBreak">{{ items.index + 1 }}</p>
                 
                  </template>

                  <template #cell(item_name)="items">
                   
                    <p class="wordBreak">{{ items.item.item_name }}</p>
                 
                  </template>

                  <template #cell(area_1)="items">
                    <b-form-select v-model="items.item.area_1">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_2)="items">
                    <b-form-select v-model="items.item.area_2" v-if="typeof items.item.area_2 != 'undefined'">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_3)="items">

                    <b-form-select v-model="items.item.area_3">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_4)="items">
                    <b-form-select v-model="items.item.area_4">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_5)="items">
                    <b-form-select v-model="items.item.area_5">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_6)="items">
                    <b-form-select v-model="items.item.area_6">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_7)="items">
                    <b-form-select v-model="items.item.area_7">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_8)="items">
                    <b-form-select v-model="items.item.area_8">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_9)="items">
                    <b-form-select v-model="items.item.area_9">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_10)="items">
                    <b-form-select v-model="items.item.area_10">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_11)="items">
                    <b-form-select v-model="items.item.area_11">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_12)="items">
                    <b-form-select v-model="items.item.area_12">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_13)="items">
                    <b-form-select v-model="items.item.area_13">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_14)="items">
                    <b-form-select v-model="items.item.area_14">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_15)="items">
                    <b-form-select v-model="items.item.area_15">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_16)="items">
                    <b-form-select v-model="items.item.area_16">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_17)="items">
                    <b-form-select v-model="items.item.area_17">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_18)="items">
                    <b-form-select v-model="items.item.area_18">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_19)="items">
                    <b-form-select v-model="items.item.area_19">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>

                  <template #cell(area_20)="items">
                    <b-form-select v-model="items.item.area_20">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option value="Yes">{{'Yes'}}</b-form-select-option>
                      <b-form-select-option value="No">{{'No'}}</b-form-select-option>
                      
                    </b-form-select>
                  </template>




                </b-table>

                
              </b-tab>
              
            </b-tabs>
          </b-card>
        </div>
       
        
      </div>
    </b-card> 


    <b-card class="mt-1" v-if="checklistData.length > 0 && checklistData[0].building != '' && checklistData[0].data.length > 0">

      <b-row>
        <b-col md="3">
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Start Time"
            class="required"
          >
                    
            <flat-pickr
              v-model="startTime"
              class="form-control"
              :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
            />

          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
            label="End Time"
            class="required"
          >
                    
            <flat-pickr
              v-model="endTime"
              class="form-control"
              :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
            />

          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mr-1 float-right"
            @click="addMore"
            v-if="site != ''"
            :disabled="(checkdisabled() && disabledOptions)"
          >
            Add More
          </b-button>
        </b-col>
              
      </b-row>

      <b-row>
        <b-col cols="12" class="mb-2 mt-2">
          <div class="pb-2">
            <b-form-group
              label="Signature"
              class="required" 
              style="font-size:20px;color:white;text-align:center"
            />

              <center>

                    
                <div @click="openSignature('supervisor')" v-if="crew_signature.image == null">
                  
                  <!--<template #aside>-->
                      <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" size="200px" rounded />
                  <!--</template>-->
                </div>

                <div class="d-flex flex-wrap">
                    <input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload" >
                </div>

                    

                <img :src="crew_signature.image" width="200px" class="rounded" height="180" v-if="crew_signature.image != null" style="background: #ffffff">
                
              </center>
              <center>
                  <b-button variant="danger" class="mt-1 ml-2" v-if="crew_signature.image != null" @click="removeSign('supervisor')" :disabled="disabledOptions">
                      <feather-icon icon="XIcon" />
                  </b-button>
              </center>

          </div>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class = "mr-1"
            variant="outline-secondary"
            @click="goBack()"
          >
            Cancel
          </b-button>

        <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "danger" class = "mr-1" @click="discardItem(id)" v-if="id != null && typeof $route.params.id == 'undefined'">
          Discard Draft
        </b-button>  

        <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "primary" class = "mr-1" type="submit" @click="status = 'draft'" v-if="typeof $route.params.id == 'undefined'">
            {{ id == null ? 'Save as Draft' : 'Update Draft'}}
        </b-button>   

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mr-1"
            type="submit"
            @click="status = 'completed'"
          >
            {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Save' }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            v-if="typeof $route.params.id != 'undefined' && ledit == false"
            @click="openSignature('approver')"
          >
            {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Approve' }}
          </b-button>

        </b-col>
      </b-row>
    </b-card> 

    </b-form>


    <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
        @show="hideSignature"
      >
      
      <div v-if="show_default == true">
        <b-row>

          <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>
        
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'administrator' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>


          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <b-form-group
                label="Remarks"
                class=""
              >
                <b-form-textarea
                  placeholder=""
                  rows="3"
                  v-model="approve_remark"
                />
              </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="6">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  @click="show_default = false"
                >
                <span class="text-nowrap">Add New Signature</span>
              </b-button>
            </b-col>

            <b-col md="6">
              <b-button
                  variant="warning"
                  class="mt-0 float-right w-100"
                  @click="saveDefault()"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'administrator' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>


          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <b-form-group
                label="Remarks"
                class=""
              >
                <b-form-textarea
                  placeholder=""
                  rows="3"
                  v-model="approve_remark"
                />
              </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="3" v-if="typeof $route.params.id == 'undefined'">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  v-if="having_default == true"
                  @click="show_default = true"
                >
                <span class="text-nowrap">Default</span>
              </b-button>
            </b-col>


          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BFormCheckbox,BFormDatepicker,BBreadcrumb,BTabs, BTab,VBTooltip
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
var moment = require('moment-timezone');
import Bus from "../../../event-bus";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,Datepicker,BFormCheckbox,flatPickr,vSelect,BFormDatepicker,BBreadcrumb,DatePicker,BTabs, BTab
  },
  
  data() {
    return {
      showDismissibleAlert:false,
      error_message:'',
      doc_icon: require('@/assets/images/doc.png'),
      id: null,
      status:'draft',
      checklistData:[],
      site:'',
      sites:[],
      shift:'',
      shifts:[],
      buildings:[],
      inspected_by : this.$store.getters.currentUser.full_name,
      month : '',
      months:[],
      year:'',
      years:[],
      date:moment().tz('Asia/Singapore').format('YYYY-MM-DD'),
      locations:[],
      startTime:'',
      endTime:'',
      signature_time: '',
      crew_signature : {
        image:null,
        name:'',
        type: 'supervisor',
        default:'no'
      },
      openedSignModel:null,
      tempSign : {
        image:null,
        name:'',
      },
      show_default:false,
      having_default:false,
      disabledOptions:false,
      supervisorSign : {
          image:null,
          name:'',
          type: 'supervisor',
          default:'no'
      },
      approve_signature_time:null,
      approve_remark:'',
      ledit : false,
    }

  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  methods: {
    showAlert() {      
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000
        })
    },
    createMonths(){
      var arr = [];
      for (var i = 1; i <= 12; i++) {
        var month = moment(i,'M').format('MMMM');
        var number = moment().month(month).format('MM');
        arr.push(month);
      }
      this.months = arr;
      return this.months;
    }, 
    calYears(){
      var arr = [];
      var last = moment().subtract('1','years').format('YYYY');
      var next = moment().add('1','years').format('YYYY');

      for (var i = last; i  <= next; i++) {
        arr.push(i);
      }
      this.years = arr;
      return this.years;
    }, 
    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
             role:this.$store.getters.currentUser.role,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;

                if (this.sites.length == 1 && typeof this.$route.params.id == 'undefined') {      
                    this.site = this.sites[0]._id;
                    this.siteChange();
                }
                return this.sites;
            }
        });
    },
    siteChange(){

      /*this.site != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');*/
      this.checklistData = [];
      
      this.shifts = [];
      this.shift = '';
      this.month = '';
      this.year = '';
      
      this.buildings = [];

      this.getShiftData();
      this.getBuildings();
      
      if ((this.site != '') && (this.shift != '') && (this.month != '') && (this.year != '') && (this.date != '')) {
        this.getLocationData();  
      } else {
        
        this.date = moment().tz('Asia/Singapore').format('YYYY-MM-DD');
        
      }
    },
    getShiftData(){
        return this.$store.dispatch(POST_API, {
            data:{
                site:this.site != '' ? this.site : null,
            },
            api: '/api/site-shifts'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.shifts = data;
                return this.shifts;
            }
        });
    },
    getBuildings(){
      
        return this.$store.dispatch(POST_API, {
            data:{
                role:this.$store.getters.currentUser.role,
                site:this.site,
                form: 'Flash Times'
            },
            api: '/api/all-buildings'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.buildings = this.$store.getters.getResults.data;
                return this.buildings;
            }
        });
    },
    getLocationData(){


      localStorage.setItem('routeCFCheck','no');
      
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.site != '' ? this.site : null,
             shift:this.shift != '' ? this.shift : null,
             month:this.month != '' ? this.month : null,
             year:this.year != '' ? this.year : null,
             date:this.date,
           },
           api: '/api/flash-times-data'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                this.id = data._id;

                var bArray = [];
                var checklistData =[];
                
                if (data.checklistData.length > 0) {
                  bArray = data.checklistData.map(item => { return item.building });
                  bArray = [...new Set(bArray)];



                  bArray.forEach(item => {
                    var obj = {
                      building : item,
                      area :'',
                      info:'',
                      allcodes:[],
                      data : [],
                    }

                    obj.data = data.checklistData.filter(item2 => { return (item2.building == item) && (item2.item_data.length > 0)});
                    obj.allcodes = obj.data.length > 0 ? obj.data[0].allcodes : [];
                    checklistData.push(obj);
                  })
                }

                //console.log(checklistData);

                this.checklistData = checklistData;

                if (this.checklistData.length == 0 && this.site != '' && this.shift != '' && this.month != '' && this.year != '' && this.id != null) {
                  this.addMore();
                }

                if(data.crew_signature){
                  this.crew_signature = data.crew_signature;
                } else {
                  this.crew_signature = {
                      image:null,
                      name:'',
                      type: 'supervisor',
                      default:'no'
                  };
                }
                this.startTime = data.startTime;
                this.endTime = data.endTime;
                return this.checklistData;
            }
        });
    },
    breadCrumb(){

        if(this.$route.params.id){

            var item = [{
              to:{name:'client-dashboard'},
              text: 'Dashboard',
            },{
              to:null,
              text: 'Reports',
            },{
              to:{name:'custom-forms-dashboard'},
              text: 'Form Dashboard',
            },{
              to:{name:'custom-forms-reports'},
              text:'Custom Forms'
            },{
              to:{name:'flash-time-report'},
              text:'Flash Times',
              
            },{
              to:null,
              text:'Edit',
              active:true
            }];
            return this.filterReportBreadCrum(item);

        }else{

            var item = [{
              to:{name:'client-dashboard'},
              text: 'Dashboard',
            },{
              to:{name:'custom-forms'},
              text: 'Custom Forms',
            },{
              to:null,
              text:'Flash Times',   
              active:true             
            }];
          return item;

        }
    },
    getArea(building,i){
      
      return this.$store.dispatch(POST_API, {
            data:{
                building:building,
            },
            api: '/api/flash-times-codes'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                var data = this.$store.getters.getResults.data;
                
                this.checklistData[i].allcodes = data;
                
                return this.checklistData[i];
            }
        });
    },
    
    getAreaItems(i){

      var order = 0;
      this.checklistData.forEach(item => {
        item.data.forEach(item2 => {
            order =  (item2.order > order) ? item2.order : order;
        })
      })
      order = order + 1;
      //console.log(this.checklistData[i]);
      return this.$store.dispatch(POST_API, {
            data:{
                code:this.checklistData[i].area,
                building:this.checklistData[i].building,
                site:this.site,
                order:order
            },
            api: '/api/flash-times-code-items'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              
              var data = this.$store.getters.getResults.data;
              this.checklistData[i].area = "";
              this.checklistData[i].info = "";
              this.checklistData[i].data.push(data);
              
             
              /*this.checklistData.splice(i,1); 
              this.checklistData.splice(i,0,data);            

              //this.checklistData[i] = data;

              this.checklistData[i].order = i;
              console.log(this.checklistData);*/
              return this.checklistData;
 
          }
      });
    },
    getTableHeader(level){

      var table = [];

      if (level == 1) {
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '5%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '30%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '65%'}},
           
        ]

        
      }else if (level == 2){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '5%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '25%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '35%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '35%'}} 
        ];
      }else if (level == 3){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '5%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '26%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '23%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '23%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '23%'}}
          
        ];
      }else if (level == 4){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '5%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '27%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '17%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '17%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '17%'}},
          { key: 'area_4', label: '', sortable: false , thStyle:  {width: '17%'}},
          
        ]
      }else if (level == 5){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '5%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '20%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '15%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '15%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '15%'}},
          { key: 'area_4', label: '', sortable: false , thStyle:  {width: '15%'}},
          { key: 'area_5', label: '', sortable: false , thStyle:  {width: '15%'}},
          
        ]
      }else if (level == 6){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '5%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '17%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '13%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '13%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '13%'}},
          { key: 'area_4', label: '', sortable: false , thStyle:  {width: '13%'}},
          { key: 'area_5', label: '', sortable: false , thStyle:  {width: '13%'}},
          { key: 'area_6', label: '', sortable: false , thStyle:  {width: '13%'}},
        ]
      }else if (level == 7){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '5%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '18%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '11%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '11%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '11%'}},
          { key: 'area_4', label: '', sortable: false , thStyle:  {width: '11%'}},
          { key: 'area_5', label: '', sortable: false , thStyle:  {width: '11%'}},
          { key: 'area_6', label: '', sortable: false , thStyle:  {width: '11%'}},
          { key: 'area_7', label: '', sortable: false , thStyle:  {width: '11%'}},
        ]
      }else if (level == 8){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '5%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '15%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '10%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '10%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '10%'}},
          { key: 'area_4', label: '', sortable: false , thStyle:  {width: '10%'}},
          { key: 'area_5', label: '', sortable: false , thStyle:  {width: '10%'}},
          { key: 'area_6', label: '', sortable: false , thStyle:  {width: '10%'}},
          { key: 'area_7', label: '', sortable: false , thStyle:  {width: '10%'}},
          { key: 'area_8', label: '', sortable: false , thStyle:  {width: '10%'}},
        ]
      }else if (level == 9){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '5%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '14%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '9%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '9%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '9%'}},
          { key: 'area_4', label: '', sortable: false , thStyle:  {width: '9%'}},
          { key: 'area_5', label: '', sortable: false , thStyle:  {width: '9%'}},
          { key: 'area_6', label: '', sortable: false , thStyle:  {width: '9%'}},
          { key: 'area_7', label: '', sortable: false , thStyle:  {width: '9%'}},
          { key: 'area_8', label: '', sortable: false , thStyle:  {width: '9%'}},
          { key: 'area_9', label: '', sortable: false , thStyle:  {width: '9%'}},
        ]
      }else if (level == 10){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '5%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '15%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_4', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_5', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_6', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_7', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_8', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_9', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_10', label: '', sortable: false , thStyle:  {width: '8%'}},
        ]
      }else if (level == 11){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '2%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_4', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_5', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_6', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_7', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_8', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_9', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_10', label: '', sortable: false , thStyle:  {width: '8%'}},
          { key: 'area_11', label: '', sortable: false , thStyle:  {width: '8%'}},

        ]
      }else if (level == 12){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '4%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '12%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_4', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_5', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_6', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_7', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_8', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_9', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_10', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_11', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_12', label: '', sortable: false , thStyle:  {width: '7%'}},

        ]
      }else if (level == 13){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '2%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_4', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_5', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_6', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_7', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_8', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_9', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_10', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_11', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_12', label: '', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_13', label: '', sortable: false , thStyle:  {width: '7%'}},

        ]
      }else if (level == 14){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '6%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '10%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_4', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_5', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_6', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_7', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_8', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_9', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_10', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_11', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_12', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_13', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_14', label: '', sortable: false , thStyle:  {width: '6%'}},

        ]
      }else if (level == 15){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '3%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_4', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_5', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_6', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_7', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_8', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_9', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_10', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_11', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_12', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_13', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_14', label: '', sortable: false , thStyle:  {width: '6%'}},
          { key: 'area_15', label: '', sortable: false , thStyle:  {width: '6%'}},

        ]
      }else if (level == 16){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '5%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '15%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_4', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_5', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_6', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_7', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_8', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_9', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_10', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_11', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_12', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_13', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_14', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_15', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_16', label: '', sortable: false , thStyle:  {width: '5%'}},

        ]
      }else if (level == 17){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '5%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '10%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_4', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_5', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_6', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_7', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_8', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_9', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_10', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_11', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_12', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_13', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_14', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_15', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_16', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_17', label: '', sortable: false , thStyle:  {width: '5%'}},
        ]
      }else if (level == 18){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '3%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '7%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_4', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_5', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_6', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_7', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_8', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_9', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_10', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_11', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_12', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_13', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_14', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_15', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_16', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_17', label: '', sortable: false , thStyle:  {width: '5%'}},
          { key: 'area_18', label: '', sortable: false , thStyle:  {width: '5%'}},
        ]
      }else if (level == 19){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '5%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '19%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_4', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_5', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_6', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_7', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_8', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_9', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_10', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_11', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_12', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_13', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_14', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_15', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_16', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_17', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_18', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_19', label: '', sortable: false , thStyle:  {width: '4%'}},
        ]
      }else if (level == 20){
        table = [
          { key: 'sno', label: 'S.No', sortable: false , thStyle:  {width: '5%'}},
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '15%'}},
          { key: 'area_1', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_2', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_3', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_4', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_5', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_6', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_7', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_8', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_9', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_10', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_11', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_12', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_13', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_14', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_15', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_16', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_17', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_18', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_19', label: '', sortable: false , thStyle:  {width: '4%'}},
          { key: 'area_20', label: '', sortable: false , thStyle:  {width: '4%'}},
        ]
      }


      return table;
    },
    getClass(level){
      if (level < 3 ) {
        return 'position-relative';
      }else if (level > 3 && level <= 5) {
        return 'position-relative midTable';
      }else if (level > 5 && level <= 10) {
        return 'position-relative midTableRpt';
      }else if (level > 10 && level <= 15) {
        return 'position-relative largeTable';
      }else if (level > 15 && level <= 20) {
        return 'position-relative largeTableGroup';
      } 
    },
    addMore(){

      var obj = {
          building : '',
          area :'',
          info:'',
          allcodes:[],

          data : [],
      };

      this.checklistData.push(obj);
      
    },
    removeBuilding(index){

      if (this.checklistData[index].data.length > 0) {

        var msg = 'Please remove Area information for this building !';
        Swal.fire({
            position: 'center',
            icon: 'warning',
            title: msg,
            showConfirmButton: false,
            timer: 2000
        });

      }else{

        this.checklistData.splice(index,1);
        if (this.checklistData.length == 0) {
          this.addMore();
        }
      }


      
    },
    removeAreaData(i,j){
      
      this.checklistData[i].data.splice(j,1);
      
    },
    checkUnique(i){
      var arr = [];

      this.checklistData.forEach((item) => {
          if (arr.indexOf(item.building+item.code) >= 0) {
              this.customAlert('Building Information already Exists.', '', 'warning');
              
              this.checklistData[i].code = '';
              this.checklistData[i].item_data = [];
              this.checklistData[i].num_area = 1;
              this.checklistData[i].area_1 = '';
              this.checklistData[i].area_2 = '';
              this.checklistData[i].locations = [];
          }else{
              arr.push(item.building+item.code);
          }
      })

      if (this.checklistData[i].building != '' && this.checklistData[i].code != '') {

        this.getAreaItems(i);
      }

    },
    supervisorImageUpload(event){
        var input = event.target;
        var files = event.target.files

        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(input.files[0]);
            var image = input.files[0];

            if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Please upload .jpg, .jpeg, .jpg images only',
                    showConfirmButton: false,
                    timer: 1500
                });

                this.$refs.supervisorSInput.value=null;

            } else if (image.size > 2097152) {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Maximum 2 MB files allowed to be upload.',
                    showConfirmButton: false,
                    timer: 1500
                })
                this.$refs.supervisorSInput.value=null;
            } else {
                reader.onload = (e) => {
                    this.crew_signature.image = e.target.result;
                    this.crew_signature.name = image.name;
                    this.crew_signature.default = 'no';
                }
            }
        }
    },
    removeSign(sign){
        this.crew_signature.image = null;
        this.crew_signature.name = '';
        this.crew_signature.default = 'no';
    },
    useDefault(){

      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$store.getters.currentUser._id
           },
           api: '/api/get-default-signature'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                if (data == null || data.default_signature == null) {
                  
                  this.having_default = false;
                  
                }else{

                  this.tempSign = {
                    image : data.default_signature,
                    name : 'signature.png',
                  }

                  this.having_default = true;
                }
            }
        });
    },
    uploadSign(){
      
      this.$refs['supervisorSInput'].click();
      this.$refs['signatureModel'].hide();

    },
    openSignature(type){
      
      this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');
      this.approve_remark = '';
      
      if (this.openedSignModel == null) {

          if (this.having_default == true) {
              this.show_default = true;
          }else{
            this.show_default = false;
          }

          this.$refs['signatureModel'].show();
          this.openedSignModel = type;
      }
    },
    hideSignature(){
      this.openedSignModel = null;
    },
    clearSignature(){
      this.$refs.signaturePad.clearSignature();
    },
    saveSignature(){
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      
      if (isEmpty) {

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Signature is required',
          showConfirmButton: false,
          timer: 1500
        })

      }else{
        if (this.openedSignModel == 'supervisor') {
          this.crew_signature.image = data;
          this.crew_signature.name = 'signature.png';
          this.crew_signature.type = 'supervisor';
          this.crew_signature.default = 'no';
        }else{

          this.supervisorSign.image = data;
          this.supervisorSign.name = 'signature.png';
          this.supervisorSign.type = 'supervisor';
          this.supervisorSign.default = 'no';
          this.status = 'approved';
          this.actualForm();

        }
        
        this.$refs['signatureModel'].hide();
      }
    },
    saveDefault(){

      if (this.openedSignModel == 'supervisor') {
          this.crew_signature = {
            image : this.tempSign.image,
            name : 'signature.png',
            default:'yes'
          }
        }else{

          this.supervisorSign = {
            image : this.tempSign.image,
            name : 'signature.png',
            default:'yes',
            type:'supervisor'
          }
          this.status = 'approved';
          this.actualForm();

        }
        
      this.$refs['signatureModel'].hide();
    },

    formSubmit(e){
      e.preventDefault();

      if(this.status == 'draft'){
        this.actualForm();            
      } else {    
        this.decisionAlert('Are you sure want to submit this report ?')
        .then(result => {
          if (result.value) {
            this.actualForm();            
          }
        })
      }
    },
    actualForm(){
      var error = false;
      
      for (var i = 0; i < this.checklistData.length; i++) {
        if (this.checklistData[i].building == '') {
          var msg = 'Please select building';
          
          Swal.fire({
              position: 'center',
              icon: 'warning',
              title: msg,
              showConfirmButton: false,
              timer: 2000
          });

          error = true;
          break;
        }else if (this.checklistData[i].data.length == 0){
          var building = this.buildings.filter(item => { return item._id == this.checklistData[i].building});
          var msg = 'Area information is required for building '+ building[0].name;
          
          Swal.fire({
              position: 'center',
              icon: 'warning',
              title: msg,
              showConfirmButton: false,
              timer: 2000
          });

          error = true;
          break;
        }
      }


      if (error == false) {
        var checklistData = [];
        this.checklistData.forEach(item => { 
          item.data.forEach(item2 => {
            checklistData.push(item2);
          })
        });
        
        return this.$store.dispatch(POST_API, {
          data:{
              id : this.id,
              site :this.site,
              shift : this.shift,
              date : this.date,
              month : this.month,
              year : this.year,
              inspected_by : this.inspected_by,
              checklistData : checklistData,
              status: this.status,
              crew_signature: this.crew_signature,
              startTime: this.startTime,
              endTime: this.endTime,
              form_id:this.$route.params.id,
              approve_signature: this.supervisorSign,
              approve_signature_time: this.approve_signature_time,
              approve_remark: this.approve_remark,
              role:this.$store.getters.currentUser.role,
              //building : this.building,
          },
          api : '/api/submit-flash-times-checklist'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message        = this.$store.getters.getErrors;
                this.errorAlertCF()
            } else {
                this.showDismissibleAlert = false;

                var data = this.$store.getters.getResults.data;

                this.successAlert().then((result) => {

                  if (data != null) {
                      window.open(data, '_blank');
                  }
                  localStorage.setItem('routeCFCheck','no');
                  // this.$router.go(-1);
                  Bus.$emit('counter_update');
                  if(this.$route.params.id){
                    this.$router.push({name:'flash-time-report'})
                  }else{

                    this.$router.push({ name:'custom-forms' })
                  }
                    
                });
            }
        });
      }
    },
    
    goBack(){

      var msg = 'Do you want to Go back without saving your report?';

      this.decisionAlert(msg)
      .then(result => {
          if (result.value) {
            localStorage.setItem('routeCFCheck','no');
            // this.$router.go(-1);
            if(this.$route.params.id){
              this.$router.push({name:'flash-time-report'})
            }else{

              this.$router.push({ name:'custom-forms' })
            }
              

          }
      })
    },
    discardItem(id){
      var msg = 'Are you sure want to discard this record?';

      this.decisionAlert(msg)
      .then(result => {
          if (result.value) {
              return this.$store.dispatch(POST_API, {
                  data:{
                      id     : id,
                      status : 'deleted'
                  },
                  api : "/api/change-status-flash-times-report-data",
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;

                      this.showAlert();
                  } else {
                      localStorage.setItem('routeCFCheck','no');
                      this.successAlert()
                      .then(() => {
                          
                          if(this.$route.params.id){
                            this.$router.push({name:'flash-time-report'})
                          }else{

                            this.$router.push({ name:'custom-forms' })
                          }
                      });
                  }
              });
          }
      })
      .catch(err => {
          console.log(err);
      })
    },
    removeDBLocation(i,item){

      var msg = 'Are you sure want to remove this Building record?';

      this.decisionAlert(msg)
      .then(result => {
          if (result.value) {
              return this.$store.dispatch(POST_API, {
                  data:{
                      site :this.site,
                      shift : this.shift,
                      date : this.date,
                      month : this.month,
                      year : this.year,
                      building:item.building,
                      location:item.location,
                      toilet:item.toilet 
                  },
                  api : "/api/remove-flash-times-db-location",
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;

                      this.showAlert();
                  } else {
                      //localStorage.setItem('routeCFCheck','no');
                      this.successAlert()
                      .then(() => {

                        this.checklistData.splice(i,1);
                        if (this.checklistData.length == 0) {
                          this.addMore();
                        }  
                      });
                  }
              });
          }
      })
      .catch(err => {
          console.log(err);
      })
    },
    removeAreaDBLocation(i,j){

      var msg = 'Are you sure want to remove this Area record?';
      //console.log(this.checklistData[i]);
      this.decisionAlert(msg)
      .then(result => {
          if (result.value) {
              return this.$store.dispatch(POST_API, {
                  data:{
                      site :this.site,
                      shift : this.shift,
                      date : this.date,
                      month : this.month,
                      year : this.year,
                      building:this.checklistData[i].data[j].building,
                      code:this.checklistData[i].data[j].code 
                      //location:item.location,
                  },
                  api : "/api/remove-flash-times-db-location",
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;

                      this.showAlert();
                  } else {
                      //localStorage.setItem('routeCFCheck','no');
                      this.successAlert()
                      .then(() => {

                        this.removeAreaData(i,j);  
                      });
                  }
              });
          }
      })
      .catch(err => {
          console.log(err);
      })
    },
    getFormDetails(){
      /*localStorage.setItem('routeCFCheck','yes');*/

      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id
           },
           api: '/api/get-flash-times-report-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.id            = data._id;
                this.site          = data.site;
                this.shift         = data.shift;
                this.date          = data.date;
                this.month         = data.month;
                this.year          = data.year;
                this.inspected_by  = data.inspected_by;

                //this.checklistData = data.checklistData;

                var bArray = [];
                var checklistData =[];
                
                if (data.checklistData.length > 0) {
                  bArray = data.checklistData.map(item => { return item.building });
                  bArray = [...new Set(bArray)];
                  bArray.forEach(item => {
                    var obj = {
                      building : item,
                      area :'',
                      allcodes:[],
                      data : [],
                    }

                    obj.data = data.checklistData.filter(item2 => { return (item2.building == item) && (item2.item_data.length > 0)});
                    obj.allcodes = obj.data.length > 0 ? obj.data[0].allcodes : [];
                    checklistData.push(obj);
                  })
                }

                //console.log(checklistData);

                this.checklistData = checklistData;

                if(data.signature){
                  this.crew_signature = data.signature;
                } else {
                  this.crew_signature = {
                      image:null,
                      name:'',
                      type: 'supervisor',
                      default:'no'
                  };
                }
                this.startTime = data.startTime;
                this.endTime = data.endTime;


                var role = this.$store.getters.currentUser.role;

                if (data.reject_to == role && ((data.level_one_user_role != role) && (data.level_two_user_role != role) && (data.level_three_user_role != role))) {
                    //this.disabledOptions = false;
                    this.ledit = true;
                } else if(((role == 'admin') || (role == 'administrator')) && ( (data.level_user_status != null) && (data.level_user_status.role == ''))){
                    this.ledit = true;
                }

                this.getShiftData();
                this.getBuildings();
            }
        });

    },
    disabledDate(date){
      return date < moment(new Date(this.date)).subtract(1,'days').tz('Asia/Singapore');
    },
    checkdisabled(){
      if (this.ledit == true) {
        return false;
      }else if(this.disabledOptions == true){
        return true;
      }else{
        return false;
      }
    },
    openLocations(){
      this.$router.push({ name: 'hand-sanitizer-locations',params:{site_id:this.site != '' ? this.site : 'all-site',id:'all-building',form:'Flash_Times'}})
    },
    checkDisableArea(area,index){
      var flag = false;
      
      for (var i = 0; i < this.checklistData[index].data.length; i++) {
          if (this.checklistData[index].data[i].code == area) {
            flag = true;
            
            i = (this.checklistData[index].data.length -1);  
          }
      }

      return flag
    },
    checkBuildingDisabled(building,index){

      var flag = false;
      
      for (var i = 0; i < this.checklistData.length; i++) {
          if (this.checklistData[i].building == building) {
            flag = true;
            
            i = (this.checklistData.length -1);  
          }
      }

      return flag
    },
    getAreaName(code,allcodes){
      
      var data = allcodes.filter(item => { return code == item.code});
      return 'Location :'+'\n'+ data[0].header.join('\n');
    },
    updateInfo(code,i){
      var data = this.checklistData[i].allcodes.filter(item => { return code == item.code})
      this.checklistData[i].info = data.length > 0 ? data[0].name.join(', '): '';
      return this.checklistData[i].info;
    }
  },
  mounted(){
    this.useDefault();
    this.createMonths();
    this.calYears();
    this.allSites();

    if(this.$route.params.id){
      this.disabledOptions = true;
      this.getFormDetails();
    }
  },
}
</script>

<style scoped>
  .form-inline .custom-control-label {
      margin-bottom: 0;
      font-weight: normal;
      margin-top: 2px;
      text-transform: capitalize;
  }
  .preFT {
    background-color: #343d55 !important;
  }
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
